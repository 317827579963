<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div class="mt-5 pb-6">
      <v-form v-model="valid" ref="stepForm">
        <v-tabs
          v-model="selectedDay"
          centered
          class="mb-5"
          @change="dayChanged"
        >
          <v-tabs-slider color="secondary"></v-tabs-slider>

          <v-tab v-for="(day, i) in dayValue" :key="i"> Day {{ i + 1 }} </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedDay">
          <v-tab-item v-for="(day, i) in dayValue" :key="i">
            <!-- map -->
            <div v-if="day.selectedMapStep">
              <div class="py-3 d-flex align-center justify-start">
                <!-- steps -->
                <div
                  class="secondary align-center d-flex justify-center mr-3 cursor-pointer"
                  style="width: 30px; height: 30px; border-radius: 50%"
                  v-for="(mapStep, j) in day.obj ? day.obj.schedules : []"
                  :key="j"
                  :class="
                    day.selectedMapStepIndex == j ? `map-step-active` : ''
                  "
                  @click="MapStepView(mapStep, j, i)"
                >
                  <span class="white--text font-13 font-500">{{ j + 1 }}</span>
                </div>

                <!-- add new step -->
                <div
                  class="secondary align-center d-flex justify-center mx-2 cursor-pointer"
                  style="width: 30px; height: 30px; border-radius: 50%"
                  @click="newMapStepHandler(i)"
                >
                  <span class="white--text font-13 font-500"
                    ><v-icon color="white" size="18">mdi-plus</v-icon></span
                  >
                </div>
              </div>
              <div>
                <v-row class="pt-3" align="center">
                  <div
                    class="px-2 mt-6 mb-3 cursor-pointer"
                    @click="deleteMapStep(i)"
                  >
                    <v-icon color="error">mdi-delete</v-icon>
                    <span class="error--text mt-1">Delete this step</span>
                  </div>
                  <!-- Transportation -->
                  <v-col
                    v-if="day.selectedMapStep"
                    cols="12"
                    md="12"
                    class="pt-1 pb-0"
                  >
                    <label class="grey--text font-12 font-500"
                      >Transportation</label
                    >
                    <base-select
                      :items="transportList"
                      v-model="day.selectedMapStep.transport"
                      placeholder="Select transportation"
                    ></base-select>
                  </v-col>
                  <!-- Experience Attractions -->
                  <v-col cols="12" class="pt-1 pb-0">
                    <label class="grey--text font-12 font-500"
                      >Attraction</label
                    >
                    <base-select
                      itemText="name"
                      itemValue="_id"
                      :items="attractions"
                      placeholder="Attraction"
                      v-if="day.selectedMapStep"
                      v-model="day.selectedMapStep.attraction"
                    ></base-select>
                  </v-col>

                  <!-- description -->
                  <v-col cols="12" md="12" class="pt-1 pb-0 mt-3">
                    <label class="grey--text font-12 font-500"
                      >DESCRIPTION</label
                    >
                    <base-textarea
                      sizeWord="250"
                      placeholder="What is this step about? Tell us more juicy details."
                      v-if="day.selectedMapStep"
                      v-model="day.selectedMapStep.description[language]"
                    ></base-textarea>
                  </v-col>

                  <v-col cols="12">
                    <div class="b-bottom mt-2"></div>
                  </v-col>
                  <!-- price type -->
                  <v-col
                    cols="12"
                    class="cursor-pointer d-flex align-center"
                    v-if="
                      day &&
                      day.obj &&
                      day.obj.schedules &&
                      day.obj.schedules.length > 0
                    "
                  >
                    <v-checkbox
                      v-if="day.selectedMapStep"
                      v-model="day.selectedMapStep.free"
                      :hide-details="true"
                      label="This step is free"
                      class="mr-10"
                      @click="
                        () => {
                          if (day.selectedMapStep.free) {
                            day.selectedMapStep.priceExcluded = false;
                          }
                        }
                      "
                    ></v-checkbox>
                    <v-checkbox
                      v-if="day.selectedMapStep"
                      v-model="day.selectedMapStep.priceExcluded"
                      :hide-details="true"
                      label="The price is excluded"
                      class="mr-10"
                      @click="
                        () => {
                          if (day.selectedMapStep.priceExcluded) {
                            day.selectedMapStep.free = false;
                          }
                        }
                      "
                    ></v-checkbox>
                  </v-col>
                  <!-- original price (USD) -->
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-1 pb-0 mt-3"
                    v-if="
                      day.selectedMapStep &&
                      !day.selectedMapStep.priceExcluded &&
                      !day.selectedMapStep.free
                    "
                  >
                    <label class="grey--text font-12 font-500">Price per</label>
                    <base-select
                      :items="pricingPerItems"
                      itemText="title"
                      itemValue="value"
                      placeholder="Price per"
                    ></base-select>
                  </v-col>
                  <!-- original price (USD) -->
                  <v-col
                    cols="12"
                    md="5"
                    class="pt-1 pb-0 mt-3"
                    v-if="
                      day.selectedMapStep &&
                      !day.selectedMapStep.priceExcluded &&
                      !day.selectedMapStep.free
                    "
                  >
                    <label class="grey--text font-12 font-500"
                      >Original price (USD)</label
                    >
                    <base-input
                      type="number"
                      placeholder="10, 20"
                      v-model="day.selectedMapStep.discountPrice"
                    ></base-input>
                  </v-col>
                  <!-- discounted price (USD) -->
                  <v-col
                    cols="12"
                    md="4"
                    class="pt-1 pb-0 mt-3"
                    v-if="
                      day.selectedMapStep &&
                      !day.selectedMapStep.priceExcluded &&
                      !day.selectedMapStep.free
                    "
                  >
                    <label class="grey--text font-12 font-500"
                      >Discounted price (USD)</label
                    >
                    <base-input
                      type="number"
                      placeholder="5, 10"
                      v-model="day.selectedMapStep.price"
                    ></base-input>
                  </v-col>

                  <v-col cols="12">
                    <div class="b-bottom mt-2"></div>
                  </v-col>
                  <!-- start / end TIME -->
                  <v-col cols="12" class="py-0 my-0"></v-col>
                  <v-col cols="12" md="6">
                    <span class="grey--text font-12 font-500 d-block"
                      >Start Time
                    </span>
                    <div class="d-flex align-center justify-space-between">
                      <input
                        class="ml-2 font-20 align-center"
                        style="
                          border: 0.5px solid rgb(219, 216, 216);
                          border-radius: 2px;
                        "
                        type="time"
                        v-model="day.selectedMapStep.startTime"
                      />
                    </div>
                  </v-col>

                  <!-- Duration -->
                  <v-col cols="12" md="6" class="pt-1">
                    <span class="grey--text font-12 font-500 d-block mb-3"
                      >Durations</span
                    >
                    <base-input
                      placeholder="Durations"
                      type="number"
                      v-if="day.selectedMapStep"
                      v-model="day.selectedMapStep.duration"
                      :rules="[(v) => !!v || v >= 0 || '']"
                    ></base-input>
                  </v-col>
                </v-row>
              </div>
              <div
                class="pb-3 cursor-pointer d-flex align-center mt-3"
                v-if="
                  day &&
                  day.obj &&
                  day.obj.schedules &&
                  day.obj.schedules.length > 0
                "
              >
                <v-checkbox
                  v-if="day.selectedMapStep"
                  v-model="day.selectedMapStep.guided"
                  :hide-details="true"
                  class="mr-10"
                  :label="
                    'This step is ' +
                    (day.selectedMapStep.guided ? 'guided' : 'unguided')
                  "
                ></v-checkbox>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <v-col cols="12">
          <div class="b-bottom my-1"></div>
        </v-col>
        <!-- upload photos -->
        <div
          class="collection-form-step-content mt-8 mb-6"
          :key="filesUploaded"
        >
          <div
            :key="filesUploaded"
            v-if="
              dayValue &&
              dayValue.length > 0 &&
              dayValue[selectedDay] &&
              dayValue[selectedDay].obj
            "
          >
            <div
              v-for="(file, i) in dayValue[selectedDay].obj.schedules[
                selectedMapStepIndex
              ].image"
              :key="i"
            >
              <div
                class="upload-item d-flex align-center justify-space-between px-6 py-3 mb-2 whitebg"
              >
                <div @click="previewHandler(file)">
                  <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
                  <span class="font-14 black--text d-inline-block ml-2">{{
                    file.fileName ||
                    file.name ||
                    (file.mediaId && file.mediaId.url)
                  }}</span>
                </div>
                <v-icon
                  class="upload-item-delete"
                  size="16"
                  @click="deleteFile(i)"
                  >mdi-close</v-icon
                >
              </div>
            </div>
          </div>

          <!-- upload new -->
          <div class="upload-item px-6 py-3">
            <div>
              <v-icon color="grayicon">mdi-folder-multiple-image</v-icon>
              <span class="font-14 black--text d-inline-block ml-2"
                >Upload Image</span
              >
            </div>
            <v-file-input
              :key="filesUploaded"
              prepend-icon=""
              @change="handleUpload($event)"
              height="50"
              hide-details="true"
              accept="image/*"
              multiple
            ></v-file-input>
          </div>
        </div>
      </v-form>

      <div class="mt-14">
        <buttons-experience
          @cancel="$emit('backHandler')"
          @next="nextHandler"
          text="Submit"
        ></buttons-experience>
      </div>

      <!-- preview image -->
      <v-dialog
        v-model="imagePreview"
        width="600"
        content-class="pa-10 no-shadow"
      >
        <div class="preview-dialog">
          <v-icon
            @click="imagePreview = false"
            class="close-preview cursor-pointer"
            color="white"
            >mdi-close</v-icon
          >

          <img
            width="100%"
            height="400px"
            class="object-cover"
            :src="imagePreviewUrl"
            alt=""
          />
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    },
    days: {
      type: Array,
      default: () => {}
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience
    // StepSix,
  },
  data: () => ({
    dayValue: [],
    name: "",
    valid: false,
    obj: { schedules: [], startTimeDay: "AM" },

    stepMapLink: "",
    pricingPerItems: [
      { title: "Person", value: "0" },
      { title: "Group", value: "1" }
    ],
    transportList: [
      { title: "Walking", value: "walk" },
      { title: "Drive", value: "drive" },
      { title: "Swim", value: "swim" },
      { title: "Sleep", value: "sleep" },
      { title: "Fly", value: "fly" },
      { title: "Eat", value: "eat" }
    ],
    maxHour: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 24 || ""
    ],

    maxMin: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 60 || ""
    ],
    filesUploaded: false,
    keyMarkChange: false,
    keyChanged: false,

    imagePreview: false,
    imagePreviewUrl: "",
    selectedDay: "",
    selectedMapStep: { image: [] },
    selectedMapStepIndex: 0,
    attractions: [],
    language: "en"
  }),
  methods: {
    handleUpload(files) {
      files.forEach((file) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          this.$store.dispatch("showSnack", {
            text: "File size too big! bigger than 1MB, select a smaller one",
            color: "error"
          });
          return;
        }
        this.dayValue[this.selectedDay].obj?.schedules[
          this.selectedMapStepIndex
        ].image.push(file);
      });
      this.filesUploaded = !this.filesUploaded;
    },
    // preview image
    previewHandler(file) {
      if (this.paramID) {
        this.imagePreview = true;
        if (file._id) this.imagePreviewUrl = file.mediaId.url;
        if (file.name) this.imagePreviewUrl = URL.createObjectURL(file);
      } else {
        this.imagePreview = true;
        this.imagePreviewUrl = URL.createObjectURL(file);
      }
    },
    deleteFile(i) {
      this.selectedMapStep.image = [
        ...this.selectedMapStep.image.filter((file, idx) => idx != i)
      ];
      this.filesUploaded = !this.filesUploaded;
    },
    dayChanged(val) {
      if (
        this.dayValue &&
        this.dayValue[val].obj &&
        this.dayValue[val].obj.schedules
      ) {
        this.dayValue[val].selectedMapStepIndex =
          this.dayValue[val].obj.schedules.length - 1;
        this.dayValue[val].selectedMapStep =
          this.dayValue[val].obj.schedules[
            this.dayValue[val].obj.schedules.length - 1
          ];

        this.selectedMapStepIndex = 0;
      }
    },
    // map steps
    newMapStepHandler(i) {
      if (
        this.dayValue &&
        this.dayValue[i].obj &&
        this.dayValue[i].obj.schedules
      ) {
        this.dayValue[i].obj.schedules.push({
          guided: false,
          startTimeDay: "AM",
          image: []
        });
        this.dayValue[i].selectedMapStepIndex =
          this.dayValue[i].obj.schedules.length - 1;
        this.dayValue[i].selectedMapStep =
          this.dayValue[i].obj.schedules[
            this.dayValue[i].obj.schedules.length - 1
          ];
        this.selectedMapStepIndex = this.dayValue[i].obj.schedules.length - 1;
        // this.dayValue[i].mapLocation = { lat: 33.8547, lng: 35.8623 };
      }
    },
    deleteMapStep(i) {
      if (
        this.dayValue &&
        this.dayValue[i].obj &&
        this.dayValue[i].obj.schedules
      ) {
        this.dayValue[i].obj.schedules = [
          ...this.dayValue[i].obj.schedules.filter(
            (step, idx) => idx != this.dayValue[i].selectedMapStepIndex
          )
        ];
        this.dayValue[i].selectedMapStepIndex = 0;
        if (this.dayValue[i].obj.schedules.length == 0) {
          this.dayValue[i].selectedMapStep = { images: [] };
        } else {
          this.selectedMapStepIndex = i;
          this.dayValue[i].selectedMapStep = this.dayValue[i].obj.schedules[0];
        }
      }
    },
    MapStepView(mapStep, j, i) {
      this.selectedMapStepIndex = j;
      this.dayValue[i].selectedMapStep = mapStep;
      this.dayValue[i].selectedMapStepIndex = j;
    },
    async nextHandler() {
      this.$refs.stepForm.validate();
      if (!this.valid) {
        setTimeout(() => {
          document.querySelector(".v-input.error--text:first-of-type");
        }, 100);
        this.$store.dispatch("showSnack", {
          text: "please check all fields in all days",
          color: `error`
        });

        return;
      }

      let scheduleArr = [];

      this.dayValue.forEach((day, idx) => {
        scheduleArr.push({
          day: {
            dayNumber: idx + 1,
            schedule: [...day.obj?.schedules]
          }
        });
      });

      this.dayValue.forEach((day) => {
        day.obj?.schedules.forEach((sch) => {
          if (sch.attraction && sch.attraction._id) {
            sch.attraction = sch.attraction._id;
          }
          if (sch.transport && sch.transport.value) {
            sch.transport = sch.transport.value;
          }
          if (sch.startTime) {
            sch.startTime = this.$convertTo12Hour(sch.startTime);
          }
        });
      });

      this.$emit("nextHandler", {
        schedules: scheduleArr
      });
    },

    fetchItemByID() {
      // set schedules
      if (
        this.objItem &&
        this.objItem.schedules &&
        this.objItem.schedules.length > 0
      ) {
        this.dayValue = [];
        this.objItem.schedules.forEach((day) => {
          this.dayValue.push({
            obj: {
              schedules: day.day.schedule
            },
            mapLocation: { lat: 33.8547, lng: 35.8623 },
            selectedMapStep: { startTimeDay: "AM", description: {} },
            selectedMapStepIndex: 0
          });
        });
      }
      this.dayValue.forEach((day) => {
        day.obj?.schedules.forEach((sch) => {
          if (sch.startTime) {
            sch.startTime = this.$convertTo24Hour(sch.startTime);
          }
        });
      });
      this.selectedMapStep = this.dayValue[0].obj?.schedules[0];
    },
    async fetchAttractions() {
      let { data } = await this.$axios.get("activity/all/selectors");
      if (data) {
        this.attractions = data.miniguides;
      }
    }
  },
  created() {
    this.dayValue = this.days;
    this.language = this.$root.language;
    this.obj.country = this.$root.country.short;
    this.paramID = this.$route.params.id;
    this.dayValue = this.dayValue.map(() => {
      return {
        obj: {
          schedules: [
            {
              description: {},
              guided: false,
              image: []
            }
          ]
        },
        mapLocation: { lat: 33.8547, lng: 35.8623 },
        selectedMapStep: { description: {}},
        selectedMapStepIndex: 0
      };
    });
    this.dayValue[0].selectedMapStep = this.dayValue[0].obj?.schedules[0];
    this.dayValue[0].obj.schedules[0].startTimeDay = "AM";

    this.fetchAttractions();
    if (this.paramID) {
      this.fetchItemByID();
    }
  }
};
</script>
