<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>

    <div>
      <span class="font-22 font-500 mt-5 mb-0 d-block"
        >Step 1 of 3: Main discounted attraction details</span
      >

      <v-form v-model="valid" @submit.prevent="submitForm" ref="form">
        <div class="mt-5">

          <v-row>
            <!-- **** Main Info **** -->
            <v-col cols="12" class="mb-0 pt-0 mt-8">
              <v-row>
                <!-- Experience Attractions -->
                <v-col cols="12" class="pt-1 pb-0">
                  <label class="grey--text font-12 font-500">Guesthouses</label>
                  <base-select
                    v-model="attraction"
                    :items="attractions"
                    itemText="name"
                    itemValue="_id"
                    placeholder="Select Guesthouses"
                  >
                  </base-select>
                </v-col>

                <!-- description -->
                <v-col cols="12">
                  <label class="grey--text font-12 font-500"
                    >Experience Name</label
                  >
                  <base-input
                    :rules="[(v) => !!v || 'this field is require']"
                    v-model="obj.activityType[language]"
                    placeholder="Add a short, clear description"
                  ></base-input>
                </v-col>

                <!-- description -->
                <v-col cols="12">
                  <label class="grey--text font-12 font-500">DESCRIPTION</label>
                  <base-textarea
                    :rules="[(v) => !!v || 'this field is require']"
                    v-model="obj.description[language]"
                    placeholder="What is this event about? Tell us more juicy details."
                  ></base-textarea>
                </v-col>

                <!-- LOCATION -->
                <v-col cols="12" md="6" class="py-1">
                  <label class="grey--text font-12 font-500"> City Name </label>
                  <GmapAutocomplete
                    class="place-input"
                    ref="gMapAutocomplete"
                    :select-first-on-enter="true"
                    placeholder="City name"
                    @place_changed="setPlace"
                  />
                </v-col>
                <v-col cols="12" md="12" class="py-1">
                  <GmapMap
                    :clickable="true"
                    ref="maps"
                    :center="center"
                    :zoom="zoom"
                    map-type-id="roadmap"
                    style="width: 100%; height: 500px"
                    @click="addMarker"
                  >
                    <GmapMarker
                      v-if="this.marker"
                      :position="{ lat: this.marker.lat, lng: this.marker.lng }"
                    />
                  </GmapMap>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <div class="b-bottom my-1"></div>
            </v-col>

            <v-col cols="12" class="mt-14">
              <!-- buttons -->
              <buttons-experience
                @cancel="$emit('backHandler')"
                @next="handleNext"
              ></buttons-experience>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    objItem: {
      type: Object,
      default: () => {}
    },
    step: {
      type: Number,
      default: 0
    }
  },
  components: {
    ButtonsExperience
    // MainImages,
  },
  data: () => ({
    marker: undefined,
    center: { lng: 35.8984486, lat: 33.8786494 },
    zoom: 9.5,
    name: "",
    newCoverImage: "",
    valid: false,
    datetime: new Date(),
    coverImage: {},
    images: [],
    obj: {
      location: {},
      description: {},
      activityType: {}
    },
    users: [],
    dayOrHour: [
      { name: "Day", _id: 0 },
      { name: "Hour", _id: 1 }
    ],
    maxHour: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 24 || ""
    ],

    maxMin: [
      (v) => !!v || "Required",
      (v) => v >= 0 || "",
      (v) => v <= 60 || ""
    ],
    files: [],
    filesUploaded: false,

    imagePreview: false,
    imagePreviewUrl: "",

    isEdit: false,
    keyChanged: false,
    keyMarkChange: false,

    selectedUsers: [{}],
    dateKey: false,

    goodToKnow: [
      { title: "What To Bring", desc: [{ value: "" }] },
      { title: "What To Wear", desc: [{ value: "" }] },
      { title: "What To Expect", desc: [{ value: "" }] }
    ],
    attractions: [],
    selectedAttraction: {},
    attraction: "",
    loaded: false,
    language: "en"
  }),

  methods: {
    addWhatToHandler(i) {
      this.goodToKnow[i].desc.push({});
    },
    removeWhatToHandler(i, j) {
      this.goodToKnow[i].desc = [
        ...this.goodToKnow[i].desc.filter((_, idx) => idx != j)
      ];
    },
    addOption() {
      this.selectedUsers.push({});
    },
    removeOption(j) {
      this.selectedUsers = this.selectedUsers.filter((_, idx) => idx != j);
    },

    handleCoverImage(file) {
      this.newCoverImage = URL.createObjectURL(file);
      this.coverImage = file;
      this.obj.newCoverImage = URL.createObjectURL(file);
    },
    handleUpload(file) {
      this.images.push({ file: file, url: URL.createObjectURL(file) });
    },

    handleDelete(i) {
      this.images = [...this.images.filter((_, idx) => idx != i)];
    },
    dataHandler(event) {
      this.obj.date = new Date(event).toISOString();
      this.dateKey = !this.dateKey;
    },
    async fetchItemByID() {
      if (this.objItem) {
        this.obj = { ...this.objItem };
        this.selectedAttraction = this.obj.attraction;
        this.attraction = this.obj.attraction;
        if (this.obj.location) {
          this.marker = {
            lng: Number(this.obj.location.long),
            lat: Number(this.obj.location.lat)
          };
          this.center = this.marker;
          this.zoom = 15;
          this.$refs.gMapAutocomplete.$el.value = this.obj.location.address;
        }
        this.goodToKnow[0].desc = this.objItem.bring?.map((val) => {
          return { value: val.label, _id: val._d };
        });
        this.goodToKnow[1].desc = this.objItem.wear?.map((val) => {
          return { value: val.label, _id: val._d };
        });
        this.goodToKnow[2].desc = this.objItem.expect?.map((val) => {
          return { value: val.label, _id: val._d };
        });

        this.images = this.objItem.images?.map((img) => {
          return {
            url: img.mediaId ? img.mediaId.url : "",
            _id: img._id,
            mediaId: img.mediaId ? img.mediaId._id : ""
          };
        });

        if (!this.obj.location) {
          this.obj.location = {};
        } else {
          this.obj.location.address = this.objItem.location?.address;
        }
      }
    },
    handleNext() {
      this.$refs.form.validate();
      if (!this.obj.location.long || !this.obj.location.lat) {
        this.$store.dispatch("showSnack", {
          text: "Location Marker is Missing",
          color: "error"
        });
        return;
      }
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }

      this.obj.bring = this.goodToKnow[0].desc?.map((val) => {
        return { label: val?.value };
      });
      this.obj.wear = this.goodToKnow[1].desc?.map((val) => {
        return { label: val?.value };
      });
      this.obj.expect = this.goodToKnow[2].desc?.map((val) => {
        return { label: val?.value };
      });

      this.obj.attraction = this.attraction._id
        ? this.attraction._id
        : this.attraction;
      this.$emit("nextHandler", {
        obj: this.obj,
        images: this.images,
        coverImage: this.coverImage
      });
    },
    async fetchUsers() {
      let { data } = await this.$axios.get(`users/all/dropdown`);
      if (data) {
        this.users = [...data.data];
      }
    },
    async fetchAttractions() {
      let { data } = await this.$axios.get(
        "guest-house?pageSize=10000&pageNumber=1"
      );
      if (data) {
        this.attractions = data.data;
      }
    },
    setPlace(event) {
      if (event.geometry && event.geometry.location) {
        this.marker = {
          lat: event.geometry.location.lat(),
          lng: event.geometry.location.lng()
        };
        this.center = event.geometry.location;
        this.zoom = 15;
        this.obj.location.long = this.marker.lng;
        this.obj.location.lat = this.marker.lat;
        this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
      }
    },
    addMarker(event) {
      this.marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      this.obj.location.long = this.marker.lng;
      this.obj.location.lat = this.marker.lat;
      this.obj.location.address = this.$refs.gMapAutocomplete.$el.value;
    }
  },
  async created() {
    this.language = this.$root.language;
    this.obj.country = this.$root.country.short;
    await this.fetchUsers();
    await this.fetchAttractions();
    await this.fetchItemByID();
  }
};
</script>
<style scoped>
.place-input {
  height: 36px;
  line-height: 35px;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
