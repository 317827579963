<template>
  <div class="white pa-5 py-3">
    <main-section
      v-show="step == 1"
      @nextHandler="stepOneHandler"
      @backHandler="$emit('close')"
      :objItem="objItem"
    ></main-section>

    <steps-section
      v-show="step == 2"
      :key="'steps ' + step"
      @backHandler="step = 1"
      @nextHandler="submitHandler"
      :objItem="objItem"
      :days="dayValue"
    ></steps-section>
  </div>
</template>

<script>
import MainSection from "./components/Main";
import StepsSection from "./components/Steps";
export default {
  components: {
    MainSection,
    StepsSection,
  },
  props: {
    itemID: {
      type: String,
      default: ""
    },
  },
  data: () => ({
    step: 0,
    obj: { schedules: [], media: [] },
    objItem: undefined,
    dayValue: [{}, {}],
    coverImage: {},
    newCoverImage: "",
    images: []
  }),
  watch: {
    $route: {
      handler() {
        this.step = 1;
      },
    },
  },
  methods: {
    stepOneHandler(val) {
      this.step = 2;
      this.obj = { ...val.obj };
      this.images = val.images;
    },
    async submitHandler(val) {
      this.obj = { ...this.obj, ...val };
      let scheduleImages = [];
      this.obj.schedules.forEach((day, indx) => {
        day.day.schedule.forEach((schedul) => {
          schedul.image.forEach((img) => {
            scheduleImages.push({
              day_index: indx,
              scheduleId: schedul._id,
              media: img,
            });
          });
          schedul.image = [];
        });
      });
      if (!this.itemID) {
        let resCreate = await this.$axios.post(`discounted-activity`, this.obj);

        if (resCreate.data) {
          const callback_OriginalStepsImages = async () => {
          return new Promise((resolve) => {
            if (scheduleImages.length > 0) {
              resCreate.data.data.schedules.forEach((day, idx) => {
                day.day.schedule.forEach((schedul) => {
                  scheduleImages.forEach(async (img) => {
                    if (idx == img.day_index) {
                      let formDataMedia = new FormData();

                      formDataMedia.append(
                        "activityId",
                        resCreate.data.data._id
                      );
                      formDataMedia.append("dayId", day._id);
                      formDataMedia.append("scheduleId", schedul._id);
                      formDataMedia.append("media", img.media);
                      // request to upload media
                      await this.$axios.post(
                        `discounted-activity/media/schedule`,
                        formDataMedia,
                        {
                          "content-type": "multipart/form-data",
                        }
                      );
                    }
                  });
                });

                if (idx == scheduleImages.length - 1) {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });
          };

          callback_OriginalStepsImages().then(() => {
            this.$store.dispatch("showSnack", {
              text: "edited succesfully",
              color: `success`,
            });
            this.$emit('reloadData')
            this.$emit('close')
          });
        }
      } else {
        let resPut = await this.$axios.put(
          `discounted-activity/${this.itemID}`,
          this.obj
        );

        if (resPut.data) {
          resPut.data.data.schedules.forEach((day, idx) => {
            scheduleImages.forEach((sch) => {
              if (sch.day_index == idx) {
                sch.dayId = day._id;
              }
            });
          });

          const callback_OriginalStepsImages = async () => {
          return new Promise((resolve) => {
            if (scheduleImages.length > 0) {
              scheduleImages.forEach(async (schedule, idx) => {
                let formDataMedia = new FormData();

                formDataMedia.append("activityId", this.itemID);
                formDataMedia.append("dayId", schedule.dayId);
                formDataMedia.append("scheduleId", schedule.scheduleId);
                formDataMedia.append("media", schedule.media);
                // request to upload media
                await this.$axios.post(
                  `discounted-activity/media/schedule`,
                  formDataMedia,
                  {
                    "content-type": "multipart/form-data",
                  }
                );
                if (idx == scheduleImages.length - 1) {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });
          };

          callback_OriginalStepsImages().then(() => {
            this.$store.dispatch("showSnack", {
              text: "edited succesfully",
              color: `success`,
            });
            this.$emit('reloadData')
            this.$emit('close')
          });
        }
      }
    },
    dayValueHandler(val) {
      this.dayValue = [];
      for (let index = 0; index < val; index++) {
        this.dayValue.push({});
      }
    },
    async fetchItemById() {
      if (this.itemID) {
        let { data } = await this.$axios.get(
          `/discounted-activity/${this.itemID}`
        );

        if (data) {
          this.objItem = data.data;
        }
      }
    },
  },
  async created() {
    this.step = 1;
    await this.fetchItemById();
  },
};
</script>

<style lang="scss">
.experience-form {
  max-width: 50%;
  margin: auto;
  height: 100vh;
}
</style>
